"use strict";

window.addEventListener(
	"load",
	async (): Promise<void> => {
		const profileIcon = document.querySelector(".owHeaderIcons") as HTMLElement;
		if (profileIcon) {
			profileIcon.onclick = onProfileClicked;
		}

		const profileOptionsBackground = document.querySelector("#owProfileOptionsBackground") as HTMLElement;
		if (profileOptionsBackground) {
			profileOptionsBackground.onclick = closeProfileMenu;
		}
	}
);

function onProfileClicked(): void {
	const profileOptionsBackground = document.querySelector("#owProfileOptionsBackground") as HTMLElement;
	if (profileOptionsBackground) {
		profileOptionsBackground.classList.remove("owHidden");
	}
}

async function closeProfileMenu(): Promise<void> {
	const profileOptionsBackground = document.querySelector("#owProfileOptionsBackground") as HTMLElement;
	if (profileOptionsBackground) {
		profileOptionsBackground.classList.add("owHidden");
	}
}
